// swiper
var topMainSlide = new Swiper(".mainvisual--slide", {
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: "6%",
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  breakpoints: {
    768: {
      spaceBetween: "0",
      slidesPerView: 1.3,
    },
  },
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
    disabledClass: "is-disable",
  },
});

// ローディング
var images = document.getElementsByTagName("img"); // ページ内の画像取得
var gauge = document.getElementById("gauge"); // ゲージ
var loadingBg = document.getElementById("loadingBg"); // ローディング背景
var loading = document.getElementById("load"); // ローディング要素
var imgCount = 0;
var baseCount = 0;
var gaugeMax = 100; // ゲージの幅指定
var current; // 画像の読み込み

for (var i = 0; i < images.length; i++) {
  var img = new Image(); // 画像の作成
  // 画像読み込み完了したとき

  img.onload = function () {
    imgCount += 1;
  }; // 画像読み込み失敗したとき

  img.onerror = function () {
    imgCount += 1;
  };

  img.src = images[i].src; // 画像にsrcを指定して読み込み開始
} // ローディング処理
if ($("body").hasClass("top")) {
  var nowLoading = setInterval(function () {
    if (baseCount <= imgCount) {
      // baseCountがimgCountを追い抜かないようにする
      // 現在の読み込み具合のパーセントを取得
      current = Math.floor((baseCount / images.length) * 100); // ゲージの変更

      gauge.style.width = current + "%";
      baseCount += 1; // 全て読み込んだ時

      if (baseCount > images.length) {
        // ローディング要素の非表示
        clearInterval(nowLoading);

        setTimeout(function () {
          $(".load").addClass("hide"); // ローディングの終了
          $(".gnavi, .lang, #btn-navi").addClass("go"); // ローディングの終了
        }, 400);
      }
    }
  }, 50);
}

var height = window.innerHeight;
document.documentElement.style.setProperty("--vh", height / 100 + "px");

//navi
$("#btn-navi").on("click", function () {
  $(".header").toggleClass("open");
  $("html").toggleClass("no-scroll");
});
$(".lang--icon").on("click", function () {
  $(".lang-item").toggleClass("open");
});

// TOPに戻るボタン
var topBtn = $(".pagetop");
//スムーススクロールでページトップへ
topBtn.click(function () {
  $("body,html").animate(
    {
      scrollTop: 0,
    },
    500
  );
  return false;
});

//mainvisual
function setHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}
setHeight();
window.addEventListener("resize", setHeight);


// スクロール
var pageID = $("html").data("id");
if (!$("body").hasClass("temp--no-main")) {
  $(window).scroll(function () {
    if ($(window).scrollTop() <= 0) {
      $(".header").removeClass("on");
    } else {
      $(".header").addClass("on");
    }
  });
}

// top animation
$(window).scroll(function () {
  const wHeight = $(window).height();
  const scrollAmount = $(window).scrollTop();
  $(".js-scroll-item").each(function () {
    const targetPosition = $(this).offset().top;
    if (scrollAmount > targetPosition - wHeight + 60) {
      $(this).addClass("js-scroll");
    }
  });
});

// cookie
window.addEventListener("DOMContentLoaded", function () {
  "use strict";
  //localStorage から ga_cookie_opt_in と see-detail の値を取得
  var cookieOptin = localStorage.getItem("ga_cookie_opt_in");

  if (cookieOptin == null) {
    var ref = document.referrer; // リファラ情報を得る
    var hereHost = window.location.hostname; // 現在ページのホスト(ドメイン)名を得る

    // ホスト名が含まれるか探す正規表現を作る(大文字・小文字を区別しない)
    var sStr = "^https?://" + hereHost;
    var rExp = new RegExp(sStr, "i");

    //Disable Google Analytics（UA-XXXX-Y の部分は正しいものに書き換えてください）
    window["ga-disable-UA-37965828-1"] = true;
  }
});


//---------------------------------------
//loadイベント
//---------------------------------------
window.addEventListener("load", function () {
  //localStorage から ga_cookie_opt_in の値を取得
  var cookieOptin = localStorage.getItem("ga_cookie_opt_in");

  if (cookieOptin == null) {
    console.log("none");
    $("#cookie-pop-bn").removeClass("off");
  }
});

//---------------------------------------
//同意ボタン押下
//---------------------------------------
$(".accept").on("click", function () {
  localStorage.setItem("ga_cookie_opt_in", "yes");
  document.getElementById("cookie-pop-bn").classList.add("pop-remove");
  //CSS Animation が完了するまで待機
  window.setTimeout("window.location.reload(false)", 500);
});

$.fn.textShortCut = function (opt) {
  var self = this;
  var defaults = {
    cutText: "...",
    textBackCut: 0,
  };

  var settings = $.extend(defaults, opt);
  self.cutText = settings.cutText;
  self.textBackCut = settings.textBackCut;
  self.shortCutTimer;
  self.origin = [];

  self.getShortCutLoop = function () {
    var length = $(document).find(self).length;
    for (var i = 0; i < length; i++) {
      if (!self.origin[i])
        self.origin[i] = $(document).find(self).eq(i).clone();
    }
    $(window).trigger("resize");
  };
  self.textShortCut = function () {
    var length = $(document).find(self).length;
    if (length > 0) {
      for (var i = 0; i < length; i++) {
        if (self.origin[i]) {
          var $target = $(document).find(self).eq(i);
          var html = self.origin[i].html().trim();
          var origin = html;
          var $clone = self.origin[i].clone();

          $clone
            .css({
              display: "none",
              position: "absolute",
              overflow: "visible",
            })
            .width($target.width())
            .height("auto");

          $target.after($clone);
          while (html.length > 0 && $clone.height() > $target.height()) {
            html = html.substr(0, html.length - 1);
            $clone.html(html);
          }

          if (origin.length != html.length) {
            html = html.substr(0, html.length - self.cutText.length + 2);
            $clone.html(html);

            if (self.textBackCut <= 0) $clone.html(html + self.cutText);
            if (self.textBackCut > 0) {
              $clone.html($clone.text().trim());
              var text = $clone
                .text()
                .substr(0, $clone.text().length - self.textBackCut);
              $clone.html(text + self.cutText);
            }
          }

          $target.html($clone.html());
          $clone.remove();
        }
      }
    }
  };
  $(window).on("resize", function () {
    clearTimeout(self.shortCutTimer);
    self.shortCutTimer = setTimeout(function () {
      self.textShortCut();
    }, 250);
  });
  self.getShortCutLoop();
};
$(".js-load-item").addClass("js-scroll"); // ローディングの終了
